body {
  margin: 0;
  padding: 0;
  background-color: #2b2b2b;
  color: #e7e7e7;
}
.card:hover, .card:active{
  -webkit-transform : scale(1.05);
          transform : scale(1.05);
  cursor: pointer;
  color: rgb(236, 159, 15)
}
.app-head{
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  background-color: #2b2b2b;
  border-bottom: 1px solid #e7e7e7;
}

.app-body{
  margin-top: 100px;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid orange;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

a{
  padding: 1px 8px;
}
.App {
  text-align: center;
}

button {
  cursor: pointer;
}

input,
button {
  border: none;
  outline: none;
}

.footer {
  background-color: orange;
  padding: 5px;
  text-align: center;
}
.footer p {
  font-size: 17px;
}
.footer p a {
  color: black;
  opacity: 0.7;
}
.footer div a {
  color: black;
  opacity: 0.85;
}

.poster{
    width: 40%;
    height: auto;
}
.main{
    display: flex;
}
.infoPage{
    padding: 10px;
    text-align: left;
    margin-top: 25px;
}
.info{
    padding: 14px;
}
p{
    margin: 5px
}
.moreInfo{
    text-align: center;
    padding: 12px;
}

  
  

@media screen and (max-width:800px){
    .poster{
        height: 250px;
    }
}
